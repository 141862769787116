import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, VStack } from "@chakra-ui/react";
import { Facility } from "features/facility/use-facility-api";
import { ValidationErrors } from "features/ui/ValidationErrors";
import { useRef } from "react";
import { useCreateOrUpdateUser, User, UserRequest } from "./use-user-api";

export interface UserFormProps {
  user?: User
  isOpen: boolean;
  facilities: Facility[];
  onClose: () => void;
}

export const UserForm = ({ user, isOpen, onClose, facilities }: UserFormProps) => {
  const initialRef = useRef(null);
  const { mutation, errors } = useCreateOrUpdateUser({onSuccess: onClose});

  const formHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    // retrieve form data
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const firstName = formData.get("firstName") as string;
    const lastName = formData.get("lastName") as string;
    const facilityId = formData.get("facilityId") as string;

    // create the request
    const userRequest: UserRequest = {
      id: user?.id,
      email,
      password: email,
      firstName,
      lastName,
      facilityId
    }

    mutation.mutate(userRequest);
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <form onSubmit={formHandler}>
            <ModalHeader>Create a New User</ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <ValidationErrors errors={errors} />
              <VStack spacing={4}>
                <HStack spacing={2}>

                  <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input name='firstName' required ref={initialRef} type='text' defaultValue={user?.firstName} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input name='lastName' required type='text' defaultValue={user?.lastName} />
                  </FormControl>

                </HStack>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input name='email' required type='email' defaultValue={user?.email} />
                </FormControl>
                <FormControl>
                  <FormLabel>Facility</FormLabel>
                  <Select name="facilityId" placeholder='Select a Facility' defaultValue={user?.facilityId}>
                    {facilities?.map((f) => <option key={f.id} value={f.id}>{f.name}</option>)}
                  </Select>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button disabled={mutation.isLoading} type='submit' colorScheme='blue' mr={3}>
                Save
              </Button>
              <Button disabled={mutation.isLoading} onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}