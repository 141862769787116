import { Button, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { Facility } from 'features/facility/use-facility-api';
import { DataTable } from 'features/ui/data-table';
import { User } from './use-user-api';
import { UserForm } from './user-form';

export interface UsersTableProps {
  users: User[];
  facilities: Facility[];
}

export const UsersTable = ({ users, facilities }: UsersTableProps) => {
  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor("firstName", {
      cell: (props) => props.getValue(),
      header: "First Name"
    }),
    columnHelper.accessor("lastName", {
      cell: (props) => props.getValue(),
      header: "Last Name"
    }),
    columnHelper.accessor("email", {
      cell: (props) => props.getValue(),
      header: "Email"
    }),
    columnHelper.accessor("authority", {
      cell: (props) => props.getValue(),
      header: "Authority"
    }),
    columnHelper.accessor("facilityId", {
      cell: (props) => facilities.find((f) => f.id === props.getValue())?.name,
      header: "Facility"
    }),
    columnHelper.display({
      id: 'actions',
      cell: props => <RowActions row={props.row} facilities={facilities || []} />,
      header: "Actions"
    })
  ];

  return (
    <DataTable title='Users' data={users} columns={columns} />
  )
}

interface RowActionsProps {
  row: Row<User>;
  facilities: Facility[];
}

export const RowActions = ({ row, facilities }: RowActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme='teal' size='sm' variant='outline'>
        Edit
      </Button>
      <UserForm user={row.original} isOpen={isOpen} onClose={onClose} facilities={facilities} />
    </>
  )
}