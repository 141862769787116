import { Box, Flex, Stack } from '@chakra-ui/react'
import { FacilitySwitcher } from 'features/navigation/facility-switcher'
import { NavigationGroup } from 'features/navigation/navigation-group'
import { NavigationItem } from 'features/navigation/navigation-item'
import { useGetReports } from 'features/report/use-report-api'
import { useUser } from 'features/user/user-context'
import {
  BiBarChart, BiBuildings, BiCopyright, BiNews, BiUser
} from 'react-icons/bi'
import { useLocation } from 'react-router-dom'

export interface NavigationProps {
  onClose?: () => void;
}

export const Navigation = ({onClose}: NavigationProps) => {
  const userContext = useUser();
  const path = useLocation().pathname;
  const { isLoading, data } = useGetReports(userContext.facilityId);

  return (
    <Flex h="full" direction="column" px="4" py="4">
      <FacilitySwitcher />

      <Stack spacing="8" flex="1" overflow="auto" pt="8">

        {userContext?.user?.authority === 'ROLE_ADMIN' &&
          <NavigationGroup label="Administration">
            <NavigationItem onClose={onClose} active={path === '/admin/users'} to='/admin/users' icon={<BiUser />} label="Users" />
            <NavigationItem onClose={onClose} active={path === '/admin/facilities'} to='/admin/facilities' icon={<BiBuildings />} label="Facilities" />
          </NavigationGroup>
        }

        <NavigationGroup label="Dashboard">
          <NavigationItem onClose={onClose} active={path === '/import-data'} to='/import-data' icon={<BiUser />} label="Import Data" />
          <NavigationItem onClose={onClose} active={path === '/reports'} to='/reports' icon={<BiNews />} label="Report Manager" />
        </NavigationGroup>

        {!isLoading && data && data.length > 0 &&
          <NavigationGroup label="My Reports">

            {!isLoading && data?.map(r =>
              <NavigationItem onClose={onClose} key={r.id} active={path === `/report-data/${r.id}`} to={`/report-data/${r.id}`} icon={<BiBarChart />} label={r.name} />)}

          </NavigationGroup>
        }

      </Stack>

      <Box pt="2">
        <Stack>
          <NavigationItem onClose={onClose} subtle to='/' icon={<BiCopyright />} label="Elevation Management v1" />
        </Stack>
      </Box>

    </Flex >
  )
}