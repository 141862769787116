import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import { useDeleteProcedureFile } from './use-procedure-api';

export interface DeleteProcedureButtonProps {
  id: string;
}

export const DeleteProcedureButton = ({ id }: DeleteProcedureButtonProps) => {
  const cancelRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutation } = useDeleteProcedureFile({onSuccess: onClose});

  return (
    <>
      <Button
        colorScheme='teal'
        color='tomato' size='sm' variant='outline'
        onClick={onOpen} >
        Delete
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Procedure File
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This will delete all procedures associated with this file. You can always re-upload however.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => mutation.mutate(id)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}