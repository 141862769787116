import {
  Box,
  Button, Heading,
  HStack, Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
import { PasswordField } from 'features/ui/PasswordField';
import { ValidationErrors } from 'features/ui/ValidationErrors';
import { useNavigate } from 'react-router-dom';
import { useResetPassword } from './use-user-api';

export interface UserResetPasswordFormProps {
  token: string;
}

export const UserResetPasswordForm = ({ token }: UserResetPasswordFormProps) => {

  // hooks
  const toast = useToast();
  const navigate = useNavigate();

  const onSuccess = () => {
    toast({
      title: 'Password reset',
      description: "Please login with your new password",
      status: 'success',
      duration: 10000,
      isClosable: true,
    })
    navigate("/login");
  }
  
  const { mutation, errors } = useResetPassword({ onSuccess });

  const formHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    // retrieve form data
    const formData = new FormData(event.currentTarget);
    const password = formData.get("password") as string;

    // create the login request
    const userResetPasswordRequest = {
      token,
      password
    }

    // login
    mutation.mutate(userResetPasswordRequest);
  }

  return (
    <Stack spacing="8">
      <Stack spacing="6">
        <Stack
          spacing={{
            base: '2',
            md: '3',
          }}
          textAlign="center"
        >
          <Heading
            size={useBreakpointValue({
              base: 'xs',
              md: 'sm',
            })}
          >
            Reset Password
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Enter your new password here</Text>
          </HStack>
        </Stack>
      </Stack>
      <Box
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={useBreakpointValue({
          base: 'transparent',
          sm: 'bg-surface',
        })}
        boxShadow={{
          base: 'none',
          sm: useColorModeValue('md', 'md-dark'),
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <form onSubmit={formHandler}>
          <ValidationErrors errors={errors} />

          <Stack spacing="6">
            <Stack spacing="5">
              <PasswordField />
            </Stack>
            <Stack spacing="6">
              <Button isLoading={mutation.isLoading} variant="primary" type='submit'>Submit</Button>
            </Stack>
          </Stack>

        </form>
      </Box>
    </Stack>
  )
}