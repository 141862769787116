import {
  Container
} from '@chakra-ui/react';
import { UserForgotPasswordForm } from 'features/user/user-forgot-password-form';

/**
 * ForgotPasswordPage
 * 
 * @returns 
 */
export const ForgotPasswordPage = () => {
  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <UserForgotPasswordForm />
    </Container>)
}