import {
  Container
} from '@chakra-ui/react';
import { UserLoginForm } from 'features/user/user-login-form';

/**
 * LoginPage
 * 
 * @returns 
 */
export const LoginPage = () => {
  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <UserLoginForm />
    </Container>)
}