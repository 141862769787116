import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import { useDeleteReport } from './use-report-api';

export interface DeleteReportButtonProps {
  id: string;
}

export const DeleteReportButton = ({ id }: DeleteReportButtonProps) => {
  const cancelRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutation } = useDeleteReport({onSuccess: onClose});

  return (
    <>
      <Button
        colorScheme='teal'
        color='tomato' size='sm' variant='outline'
        onClick={onOpen} >
        Delete
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Report
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this report?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button disabled={mutation.isLoading} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={mutation.isLoading} colorScheme='red' onClick={() => mutation.mutate(id)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}