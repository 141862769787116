import { del, get, post } from "api/api-client";
import { MutationProps, useApiMutation, useApiQuery } from "api/use-api";
import { useApiConfig } from "api/use-api";
import { AxiosRequestConfig } from "axios";

export interface ProcedureFile {
  id: string;
  filename: string;
  timeCreated: Date;
  size: number;
  recordCount: number;
  errorCount: number;
  procedures: Procedure[];
  errors: ProcedureError[];
}

export interface Procedure {
  payor: string;
  surgeon: string;
  specialty: string;
  indicator: string;
  cpt1: string;
  cpt2: string;
  cpt3: string;
  cost: number;
  costAdjusted: number;
  reimbursement: number;
  implantsReimbursed: boolean;
  minutes: number;
}

export interface ProcedureError {
  error: string;
  recordNumber: number;
  values: string[];
}

const getProcedureFiles = async (facilityId: string, config: AxiosRequestConfig) => {
  return await get<ProcedureFile[]>(`/procedure/procedure-file/facility/${facilityId}`, config);
};

const getProcedureFile = async (id: string, config: AxiosRequestConfig) => {
  return await get<ProcedureFile>(`/procedure/procedure-file/${id}`, config);
};

const createProcedureFile = async (facilityId: string, request: FormData, config: AxiosRequestConfig) => {
  return await post<ProcedureFile>(`/procedure/procedure-file/facility/${facilityId}`, request, config);
};

const deleteProcedureFile = async (id: string, config: AxiosRequestConfig) => {
  return await del(`/procedure/procedure-file/${id}`, config);
};

export const useGetProcedureFiles = (facilityId: string) => {
  const config = useApiConfig();
  return useApiQuery('getProcedureFiles', () => getProcedureFiles(facilityId, config));
};

export const useGetProcedureFile = (id: string) => {
  const config = useApiConfig();
  return useApiQuery(`getProcedureFile-${id}`, () => getProcedureFile(id, config));
};

export const useCreateProcedureFile = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (request: {facilityId: string, formData: FormData}) => createProcedureFile(request.facilityId, request.formData, config);
  return useApiMutation(onSubmit, options);
};

export const useDeleteProcedureFile = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (id: string) => deleteProcedureFile(id, config);
  return useApiMutation(onSubmit, options);
};
