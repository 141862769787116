import { Box, Skeleton, useDisclosure } from '@chakra-ui/react';
import { ProcedureFileForm } from 'features/procedure/procedure-file-form';
import { ProcedureFileFormButton } from 'features/procedure/procedure-file-form-button';
import { ProcedureFilesTable } from 'features/procedure/procedure-files-table';
import { useGetProcedureFiles } from 'features/procedure/use-procedure-api';
import { useUser } from 'features/user/user-context';

export const ImportDataPage = () => {
  const userContext = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, data } = useGetProcedureFiles(userContext.facilityId);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box>
        <ProcedureFilesTable procedureFiles={data || []} />
        <ProcedureFileForm isOpen={isOpen} onClose={onClose} />
        <ProcedureFileFormButton onOpen={onOpen} />
      </Box>
    </Skeleton>
  )
}