import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const ReportFormButton = () => {
  const navigate = useNavigate();

  return (
    <Flex>
      <Spacer />
      <Box p='4' >
        <Button onClick={() => navigate('/report')}>Create Report</Button>
      </Box>
    </Flex>
  )
}