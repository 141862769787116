import { Box, Button, Flex, Spacer } from "@chakra-ui/react";

export interface FacilityFormButtonProps {
  onOpen: () => void;
}

export const FacilityFormButton = ({ onOpen }: FacilityFormButtonProps) => {
  return (
    <Flex>
      <Spacer />
      <Box p='4' >
        <Button onClick={onOpen}>New Facility</Button>
      </Box>
    </Flex>
  )
}