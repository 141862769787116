import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, VStack } from "@chakra-ui/react";
import { ValidationErrors } from "features/ui/ValidationErrors";
import { useRef } from "react";
import { Facility, FacilityRequest, useCreateOrUpdateFacility } from "./use-facility-api";

export interface FacilityFormProps {
  facilityTypes: string[];
  facility?: Facility;
  isOpen: boolean;
  onClose: () => void;
}

export const FacilityForm = ({ facilityTypes, facility, isOpen, onClose }: FacilityFormProps) => {
  const initialRef = useRef(null);
  const { mutation, errors } = useCreateOrUpdateFacility({ onSuccess: onClose });

  const formHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    // retrieve form data
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const type = formData.get("type") as string;

    // create the request
    const facilityRequest: FacilityRequest = {
      id: facility?.id,
      name,
      type
    }

    mutation.mutate(facilityRequest);
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <form onSubmit={formHandler}>
            <ModalHeader>Create a new Facility</ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <ValidationErrors errors={errors} />
              <VStack spacing={4}>

                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input name='name' required ref={initialRef} placeholder='Name' type='text' defaultValue={facility?.name} />
                </FormControl>

                <FormControl>
                  <FormLabel>Type</FormLabel>
                  <Select name="type" required placeholder='Select a facility type' defaultValue={facility?.type}>
                    {facilityTypes?.map((f) => <option key={f} value={f}>{f}</option>)}
                  </Select>
                </FormControl>

              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button isLoading={mutation.isLoading} type='submit' colorScheme='blue' mr={3}>
                Save
              </Button>
              <Button isLoading={mutation.isLoading} onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}