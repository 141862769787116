import { CreateToastFnReturn, useToast } from "@chakra-ui/react";
import { AxiosError, AxiosResponse } from "axios";
import { ValidationError } from "features/ui/ValidationErrors";
import { useUser } from "features/user/user-context";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MutationFunction, useMutation, useQuery, useQueryClient } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";

const onErrorResponse = (error: AxiosError, setErrors: Dispatch<SetStateAction<ValidationError[]>>, toast: CreateToastFnReturn, navigate: NavigateFunction) => {
    const axiosError = (error as AxiosError);
    const status = axiosError?.response?.status;
    const data = axiosError?.response?.data;

    if (status === 400) {
        setErrors(data as ValidationError[]);
        return;
    }

    if (status === 401) {
        toast({
            title: 'Unauthorized',
            description: "Please log in to access this resource",
            status: 'error',
            duration: 10000,
            isClosable: true,
        })

        navigate('/login')
        return;
    }

    if (status === 403) {
        toast({
            title: 'Access Denied',
            description: "You don't currently have access to this resource",
            status: 'error',
            duration: 10000,
            isClosable: true,
        })

        return;
    }

    if (status === 404) {
        toast({
            title: 'Not Found',
            description: "We could not find the page you were looking for",
            status: 'error',
            duration: 10000,
            isClosable: true,
        })

        navigate('/reports')
        return;
    }

    toast({
        title: 'Communication Error',
        description: "Could not communicate with the server, please try again later",
        status: 'error',
        duration: 10000,
        isClosable: true,
    })

    return;
};

export const useApiConfig = () => {
    const userContext = useUser();

    // create the config with authorization headers
    const config = {
        headers: {
            authorization: userContext.token
        }
    };

    return config;
};

export const useApiQuery = <T,>(query: string, queryFunction: () => Promise<AxiosResponse<T>>) => {
    const toast = useToast();
    const navigate = useNavigate();
    const userContext = useUser();
    const [errors, setErrors] = useState<ValidationError[]>([]);

    const { isLoading, isError, data } = useQuery({
        queryKey: [query],
        queryFn: queryFunction,
        onError: (error: AxiosError) => onErrorResponse(error, setErrors, toast, navigate)
    });

    useEffect(() => {

        // update the authorization token if one was provided
        if (data) {
            const token = data.headers.authorization;

            if (token) {
                userContext.updateToken(token);
            }
        }

    }, [data, userContext]);

    return {
        isLoading,
        isError,
        errors,
        data: data?.data
    };
};

export interface MutationProps {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
}

export const useApiMutation = <T,>(mutateFunction: MutationFunction<AxiosResponse<T, any>, any>, options?: MutationProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const userContext = useUser();
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const queryClient = useQueryClient();

    const mutation = useMutation(mutateFunction, {
        onError: (error: AxiosError) => {
            onErrorResponse(error, setErrors, toast, navigate);

            if (options?.onError) {
                options.onError(error);
            }

        },
        onSuccess: (data) => {

            if (data) {
                const token = data.headers.authorization;

                if (token) {
                    userContext.updateToken(token);
                }
            }

            queryClient.invalidateQueries();
            setErrors([]);

            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
    })

    return {
        mutation,
        errors
    };
};