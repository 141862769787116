import { Button, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable } from 'features/ui/data-table';
import { FacilityForm } from './facility-form';
import { Facility } from './use-facility-api';

export interface FacilitiesTableProps {
  facilityTypes: string[];
  facilities: Facility[];
}

export const FacilitiesTable = ({ facilityTypes, facilities }: FacilitiesTableProps) => {
  const columnHelper = createColumnHelper<Facility>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => props.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("type", {
      cell: (props) => props.getValue(),
      header: "Type"
    }),
    columnHelper.display({
      id: 'actions',
      cell: props => <RowActions facilityTypes={facilityTypes} row={props.row} />,
      header: "Actions"
    })
  ];

  return (
    <DataTable title='Facilities' data={facilities} columns={columns} />
  )
}

interface RowActionsProps {
  row: Row<Facility>;
  facilityTypes: string[];
}

export const RowActions = ({ facilityTypes, row }: RowActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme='teal' size='sm' variant='outline'>
        Edit
      </Button>
      <FacilityForm facilityTypes={facilityTypes} facility={row.original} isOpen={isOpen} onClose={onClose} />
    </>
  )
}