import { Box, Flex, FlexProps, HStack, Skeleton, useMenuButton } from '@chakra-ui/react';
import { useGetFacilities } from 'features/facility/use-facility-api';
import { useUser } from 'features/user/user-context';
import { BiBuildingHouse } from 'react-icons/bi';
import { HiSelector } from 'react-icons/hi';

export const FacilitySwitcherButton = (props: FlexProps) => {
  const buttonProps = useMenuButton(props);
  const userContext = useUser();
  const { isLoading, data: facilities } = useGetFacilities();

  return (
    <Skeleton isLoaded={!isLoading}>
      <Flex
        as="button"
        {...buttonProps}
        w="full"
        display="flex"
        alignItems="center"
        rounded="lg"
        bg="gray.700"
        px="3"
        py="2"
        fontSize="sm"
        userSelect="none"
        cursor="pointer"
        outline="0"
        transition="all 0.2s"
        _active={{ bg: 'gray.600' }}
        _focus={{ shadow: 'outline' }}
      >
        <HStack flex="1" spacing="3">
          <BiBuildingHouse color="white" size='2.5em' />
          <Box textAlign="start">
            <Box noOfLines={1} fontWeight="semibold">
              {facilities?.find(f => f.id === userContext.user?.facilityId)?.name}
            </Box>
            <Box fontSize="xs" color="gray.400">
              Current Facility
            </Box>
          </Box>
        </HStack>
        <Box fontSize="lg" color="gray.400">
          <HiSelector />
        </Box>
      </Flex>
    </Skeleton>
  )
}
