import { Button, ButtonGroup, Card, CardBody, IconButton, Skeleton, Stack, StackDivider, Text, useBoolean } from '@chakra-ui/react';
import { ReportDataItem, ReportDataNetRevenue } from 'features/report/use-report-api';
import { useEffect, useState } from 'react';
import { BiBarChartSquare, BiPieChartAlt } from 'react-icons/bi';
import { Bar, BarChart, Cell, LabelList, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatCurrency, getChartHeight, getLabelWidth } from './chart-util';

const colors = [
  "#ff8686",
  "#f9868a",
  "#f2868e",
  "#ec8793",
  "#e58797",
  "#df879b",
  "#d9879f",
  "#d287a3",
  "#cc88a7",
  "#c588ac",
  "#bf88b0",
  "#b888b4",
  "#b288b8",
  "#ac89bc",
  "#a589c0",
  "#9f89c5",
  "#9889c9",
  "#928acd",
  "#8c8ad1",
  "#858ad5",
  "#7f8ad9",
  "#788ade",
  "#728be2",
  "#6b8be6",
  "#658bea",
  "#5f8bee",
  "#588bf2",
  "#528cf7",
  "#4b8cfb",
  "#458cff"];

export interface NetRevenueChartProps {
  isLoading: boolean;
  reportData?: ReportDataNetRevenue
}

export const NetRevenueChart = ({ isLoading, reportData }: NetRevenueChartProps) => {
  const [flag, setFlag] = useBoolean();
  const [chartData, setChartData] = useState<ReportDataItem[]>([]);
  const [chartActive, setChartActive] = useState('byPayor');

  useEffect(() => {
    setChartData(reportData?.byPayor || []);
  }, [reportData]);

  const onChartClick = (id: string) => {

    if (id === 'byPayor') {
      setChartActive('byPayor');
      setChartData(reportData?.byPayor || []);
    }

    if (id === 'byProcedure') {
      setChartActive('byProcedure');
      setChartData(reportData?.byProcedure || []);
    }

    if (id === 'bySpecialty') {
      setChartActive('bySpecialty');
      setChartData(reportData?.bySpecialty || []);
    }

    if (id === 'bySurgeon') {
      setChartActive('bySurgeon');
      setChartData(reportData?.bySurgeon || []);
    }

  }

  return (
    <>
      {reportData &&
        <Skeleton isLoaded={!isLoading} mb={10}>
          <Card bg='white'>
            <CardBody>

              <Stack spacing="5" divider={<StackDivider />}>
                <Stack justify="space-between" direction={{ base: 'column', sm: 'row' }} spacing="5" mb={5}>
                  <Stack spacing="1">
                    <Text fontSize="lg" fontWeight="medium">
                      Net Revenue by
                      {chartActive === 'byPayor' && ' Payor'}
                      {chartActive === 'byProcedure' && ' Procedure'}
                      {chartActive === 'bySpecialty' && ' Specialty'}
                      {chartActive === 'bySurgeon' && ' Surgeon'}
                    </Text>
                    <Text fontSize="sm" color="muted">
                      Net Revenue broken down by category.
                    </Text>
                  </Stack>
                  <ButtonGroup display={'flex'} flexWrap={'wrap'}>
                    <Button ml={2} my={1} variant="outline" isActive={chartActive === 'byPayor'} onClick={() => onChartClick('byPayor')} >By Payor</Button>
                    <Button my={1} variant="outline" isActive={chartActive === 'byProcedure'} onClick={() => onChartClick('byProcedure')} >By Procedure</Button>
                    <Button my={1} variant="outline" isActive={chartActive === 'bySpecialty'} onClick={() => onChartClick('bySpecialty')} >By Specialty</Button>
                    <Button my={1} variant="outline" isActive={chartActive === 'bySurgeon'} onClick={() => onChartClick('bySurgeon')} >By Surgeon</Button>
                    <IconButton my={1} variant="outline" aria-label='Pie/Bar' icon={!flag ? <BiPieChartAlt /> : <BiBarChartSquare />} onClick={setFlag.toggle} />
                  </ButtonGroup>
                </Stack>
              </Stack>

              {!flag &&
                <ResponsiveContainer height={getChartHeight(chartData)} >
                  <BarChart layout="vertical" data={chartData} margin={{ top: 5, right: 35, left: 35, bottom: 5 }}>
                    <YAxis tick={{ fontSize: 14, color: 'black' }}
                      tickLine={false}
                      width={getLabelWidth(chartData)}
                      type="category" dataKey="name" />
                    <XAxis type="number" tickFormatter={formatCurrency} />
                    <Tooltip formatter={(value) => [formatCurrency(parseInt(value.toString())), "Revenue"]} />
                    <Bar dataKey="value" fill="#8884d8" >
                      {colors.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                      ))
                      }
                      <LabelList formatter={formatCurrency} dataKey="value" position="right" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              }

              {flag &&
                <ResponsiveContainer aspect={1}>
                  <PieChart>
                    <Pie animationDuration={100} dataKey="value" data={chartData} fill="#8884d8" nameKey="name" labelLine={false} label={(entry) => entry.name}>
                      {
                        chartData.map((entry, index) => <Cell name={entry.name} fill={colors[index % colors.length]} />)
                      }
                    </Pie>
                    <Tooltip formatter={(value, name) => [formatCurrency(parseInt(value.toString())), name]} />
                  </PieChart>
                </ResponsiveContainer>
              }

            </CardBody>
          </Card>
        </Skeleton>
      }
    </>
  )
}