import { Skeleton } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'features/ui/data-table';
import { Procedure } from './use-procedure-api';

export interface ProceduresTableProps {
  loading: boolean;
  procedures: Procedure[];
}

export const ProceduresTable = ({ loading, procedures }: ProceduresTableProps) => {
  const columnHelper = createColumnHelper<Procedure>();

  const columns = [
    columnHelper.accessor("payor", {
      cell: (props) => props.getValue(),
      header: "Payor"
    }),
    columnHelper.accessor("surgeon", {
      cell: (props) => props.getValue(),
      header: "Surgeon"
    }),
    columnHelper.accessor("specialty", {
      cell: (props) => props.getValue(),
      header: "Speciality"
    }),
    columnHelper.accessor("indicator", {
      cell: (props) => props.getValue(),
      header: "Indicator"
    }),
    columnHelper.accessor("cpt1", {
      cell: (props) => props.getValue(),
      header: "CPT 1"
    }),
    columnHelper.accessor("cpt2", {
      cell: (props) => props.getValue(),
      header: "CPT 2"
    }),
    columnHelper.accessor("cpt3", {
      cell: (props) => props.getValue(),
      header: "CPT 3"
    }),
    columnHelper.accessor("cost", {
      cell: (props) => props.getValue(),
      header: "Cost"
    }),
    columnHelper.accessor("costAdjusted", {
      cell: (props) => props.getValue(),
      header: "Cost Adjusted"
    }),
    columnHelper.accessor("reimbursement", {
      cell: (props) => props.getValue(),
      header: "Reimbursement"
    }),
    columnHelper.accessor("implantsReimbursed", {
      cell: (props) => props.getValue(),
      header: "Implants Reimbursed"
    }),
    columnHelper.accessor("minutes", {
      cell: (props) => props.getValue(),
      header: "Minutes"
    })
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <DataTable title='Procedures' data={procedures} columns={columns} />
    </Skeleton>
  )
}