import { Box, Container, Skeleton, Stack, StackDivider, useColorModeValue } from '@chakra-ui/react';
import { useGetProcedureFiles } from 'features/procedure/use-procedure-api';
import { ReportForm } from 'features/report/report-form';
import { useGetReport } from 'features/report/use-report-api';
import { useUser } from 'features/user/user-context';
import { useParams } from 'react-router-dom';

export const ReportEditPage = () => {
  const { id } = useParams();
  const userContext = useUser();
  const { isLoading: isLoadingProcedureFiles, data: procedureFiles } = useGetProcedureFiles(userContext.facilityId);
  const { isLoading: isLoadingReport, data: report } = useGetReport(id || '');

  return (
    <Skeleton isLoaded={!isLoadingProcedureFiles && !isLoadingReport}>
      <Box as="section">
        <Container >
          <Box
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            p={{ base: '4', md: '6' }}
          >
            <Stack spacing="5" divider={<StackDivider />}>
              <ReportForm procedureFiles={procedureFiles || []} report={report} />
            </Stack>
          </Box>
        </Container>
      </Box >
    </Skeleton>
  )
}