import {
  Menu, MenuItem, MenuList, useColorModeValue
} from '@chakra-ui/react';
import { useUser } from 'features/user/user-context';
import { FacilitySwitcherButton } from './facility-switcher-button';

export const FacilitySwitcher = () => {
  const userContext = useUser();

  return (
    <Menu>
      <FacilitySwitcherButton />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        <MenuItem rounded="md" onClick={userContext.logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}
