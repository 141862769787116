import { get, post, put } from "api/api-client";
import { MutationProps, useApiConfig, useApiMutation, useApiQuery } from "api/use-api";
import { AxiosRequestConfig } from "axios";

export interface User {
  id: string;
  email: string;
  authority: string;
  firstName: string;
  lastName: string;
  facilityId: string;
}

export interface UserRequest {
  id?: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  facilityId: string;
}

export interface UserTokenRequest {
  email: string;
  password: string;
  longExpire: boolean;
}

export interface UserForgotPasswordRequest {
  email: string;
}

export interface UserResetPasswordRequest {
  token: string;
  email: string;
}

const getToken = async (request: UserTokenRequest) => {
  return await post<User>("/user/token", request);
};

const getUsers = async (config: AxiosRequestConfig) => {
  return await get<User[]>("/user", config);
};

const createUser = async (request: UserRequest, config: AxiosRequestConfig) => {
  return await post<User>("/user", request, config);
};

const updateUser = async (id: string, request: UserRequest, config: AxiosRequestConfig) => {
  return await put<User>(`/user/${id}`, request, config);
};

const forgotPassword = async (request: UserForgotPasswordRequest) => {
  return await post<User>("/user/forgot-password", request);
};

const resetPassword = async (request: UserResetPasswordRequest) => {
  return await post<User>("/user/reset-password", request);
};

export const useGetToken = (options: MutationProps) => {
  const onSubmit = (request: UserTokenRequest) => getToken(request);
  return useApiMutation(onSubmit, options);
};

export const useGetUsers = () => {
  const config = useApiConfig();
  return useApiQuery('getUsers', () => getUsers(config));
};

export const useCreateOrUpdateUser = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (request: UserRequest) => request.id ? updateUser(request.id, request, config) : createUser(request, config);
  return useApiMutation(onSubmit, options);
};

export const useForgotPassword = (options: MutationProps) => {
  const onSubmit = (request: UserForgotPasswordRequest) => forgotPassword(request);
  return useApiMutation(onSubmit, options);
};

export const useResetPassword = (options: MutationProps) => {
  const onSubmit = (request: UserResetPasswordRequest) => resetPassword(request);
  return useApiMutation(onSubmit, options);
};
