import {
  Box,
  Button,
  Checkbox, FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import { PasswordField } from 'features/ui/PasswordField';
import { ValidationErrors } from 'features/ui/ValidationErrors';
import { useUser } from 'features/user/user-context';
import {
  Link,
  useLocation, useNavigate
} from "react-router-dom";
import { useGetToken, User } from './use-user-api';

/**
 * UserLoginForm
 * 
 * @returns 
 */
export const UserLoginForm = () => {

  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useUser();
  const from = location.state?.from?.pathname || "/reports";

  const onSuccess = (user: User) => {
    userContext.updateUser(user);
    userContext.updateFacilityId(user.facilityId);
    navigate(from, { replace: true });
  }

  const { mutation, errors } = useGetToken({ onSuccess });

  const formHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    // retrieve form data
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const longExpire = formData.get("remember-me") as string === 'true';

    // create the login request
    const userTokenRequest = {
      email, password, longExpire
    }

    // login
    mutation.mutate(userTokenRequest);
  }

  return (
    <Stack spacing="8">
      <Stack spacing="6">
        <Stack
          spacing={{
            base: '2',
            md: '3',
          }}
          textAlign="center"
        >
          <Heading
            size={useBreakpointValue({
              base: 'xs',
              md: 'sm',
            })}
          >
            Log in to your account
          </Heading>
          <HStack spacing="1" justify="center">
            <Text color="muted">Elevation Management Solutions</Text>
          </HStack>
        </Stack>
      </Stack>
      <Box
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={useBreakpointValue({
          base: 'transparent',
          sm: 'bg-surface',
        })}
        boxShadow={{
          base: 'none',
          sm: useColorModeValue('md', 'md-dark'),
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <form onSubmit={formHandler}>
          <ValidationErrors errors={errors} />

          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isRequired>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" name="email" type="email" required={false} />
              </FormControl>
              <PasswordField />
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked name='remember-me' value='true'>Remember me</Checkbox>
              <Link to="/forgot-password">
                <Button variant="link" colorScheme="blue" size="sm">
                  Forgot password?
                </Button>
              </Link>
            </HStack>
            <Stack spacing="6">
              <Button isLoading={mutation.isLoading} variant="primary" type='submit'>Sign in</Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Stack>
  )
}