import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, Heading, IconButton, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Navigation } from 'features/navigation/navigation';
import { useUser } from 'features/user/user-context';
import { BiMenu } from 'react-icons/bi';
import {
  Navigate, useLocation
} from "react-router-dom";

/**
 * AuthorizedPage
 * 
 * @param children 
 * @returns children
 */
export function AuthorizedPage({ children }: { children: JSX.Element }) {
  const userContext = useUser();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile] = useMediaQuery("(max-width: 768px)")

  if (!userContext.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" id="app-container">
        {!isMobile &&
          <Box w="64" bg="gray.900" color="white" fontSize="sm">
            <Navigation />
          </Box>
        }
        {isMobile &&
          <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="xs">
            <DrawerOverlay />
            <DrawerContent bg="gray.900" p="0" m="0">
              <Box w="64" bg="gray.900" color="white" fontSize="sm">
                <Navigation onClose={onClose} />
              </Box>
            </DrawerContent>
          </Drawer>
        }
        <Box bg='grey.100' flex="1" px="6" overflow='auto'>
          <Box py="3">
            {isMobile &&
              <Flex alignItems='center' >
                <IconButton onClick={onOpen} aria-label='Search database' icon={<BiMenu />} />
                <Heading textAlign='center' flex='1' size='xs' >
                  Elevation Management
                </Heading>
              </Flex>
            }
          </Box>
          {children}
        </Box>
      </Flex>
    </Box>
  )
}