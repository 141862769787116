import { Button, Center, FormControl, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Square, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { ValidationErrors } from "features/ui/ValidationErrors";
import { useRef, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { BiCloudUpload } from "react-icons/bi";
import { useUser } from "../user/user-context";
import { useCreateProcedureFile } from "./use-procedure-api";

export interface ProcedureFileFormProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProcedureFileForm = ({ isOpen, onClose }: ProcedureFileFormProps) => {
  const userContext = useUser();
  const initialRef = useRef(null);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [fileName, setFileName] = useState<string>();

  const { getRootProps } = useDropzone({
    multiple: false,
    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file);
      setFormData(formData);
      setFileName(file.name);
    }
  });

  const { mutation, errors } = useCreateProcedureFile({onSuccess: () => {
    setFormData(new FormData());
    setFileName(undefined);
    onClose();
  }});

  const formHandler = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({
      facilityId: userContext.facilityId,
      formData,
    });
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={formHandler}>
            <ModalHeader>Import Procedure File</ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <ValidationErrors errors={errors} />
              <VStack spacing={4}>

                <FormControl>
                  <Center  {...getRootProps()}
                    borderWidth="1px"
                    borderRadius="lg"
                    px="6"
                    py="4"
                    bg={useColorModeValue('white', 'gray.800')}
                  >
                    <VStack spacing="3">
                      <Square size="10" bg="bg-subtle" borderRadius="lg">
                        <Icon as={BiCloudUpload} boxSize="5" color="muted" />
                      </Square>
                      <VStack spacing="1">
                        <HStack spacing="1" whiteSpace="nowrap">
                          <Button variant="link" colorScheme="blue" size="sm">
                            Click to Select a File
                          </Button>
                          <Text fontSize="sm" color="muted">
                            or drag and drop
                          </Text>
                        </HStack>
                      </VStack>
                    </VStack>
                  </Center>
                </FormControl>

              </VStack>

              <Center>
                {fileName &&
                  <Text mt={5} fontSize="sm" color="muted" fontWeight='bold'>
                    {fileName}
                  </Text>
                }
              </Center>

            </ModalBody>

            <ModalFooter>
              <Button isLoading={mutation.isLoading} disabled={!fileName} type='submit' colorScheme='blue' mr={3}>
                Upload
              </Button>
              <Button isLoading={mutation.isLoading} onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  )
}