import { Button } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable } from 'features/ui/data-table';
import { useNavigate } from 'react-router-dom';
import { DeleteReportButton } from './delete-report';
import { Report } from './use-report-api';

export interface ReportsTableProps {
  reports: Report[];
}

export const ReportsTable = ({ reports }: ReportsTableProps) => {
  const columnHelper = createColumnHelper<Report>();

  const columns = [
    columnHelper.accessor("name", {
      cell: (props) => props.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("volumeMaxPayors", {
      cell: (props) => props.getValue(),
      header: "Max Payors"
    }),
    columnHelper.accessor("volumeMaxProcedures", {
      cell: (props) => props.getValue(),
      header: "Max Procedures"
    }),
    columnHelper.accessor("volumeMaxSurgeons", {
      cell: (props) => props.getValue(),
      header: "Max Surgeons"
    }),
    columnHelper.accessor("volumeMaxSpecialties", {
      cell: (props) => props.getValue(),
      header: "Max Specialties"
    }),
    columnHelper.display({
      id: 'actions',
      cell: props => <RowActions row={props.row} />,
      header: "Actions"
    })
  ];

  return (
    <DataTable title='Reports' data={reports} columns={columns} />
  )
}

interface RowActionsProps {
  row: Row<Report>
}

export const RowActions = ({ row }: RowActionsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Button mr={2} onClick={() => navigate(`/report-data/${row.original.id}`)} colorScheme='teal' size='sm' variant='outline'>
        View Report
      </Button>
      <Button mr={2} onClick={() => navigate(`/report/${row.original.id}`)} colorScheme='teal' size='sm' variant='outline'>
        Edit
      </Button>
      <DeleteReportButton id={row.original.id || ''} />
    </>
  )
}