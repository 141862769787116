import axios, { AxiosResponse } from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
});

const { get, post, put, delete: del } = apiClient;

export { get, post, put, del };
export type { AxiosResponse };
