import {
  Container
} from '@chakra-ui/react';
import { UserResetPasswordForm } from 'features/user/user-reset-password-form';
import { useParams } from 'react-router-dom';

/**
 * ResetPasswordPage
 * 
 * @returns 
 */
export const ResetPasswordPage = () => {
  let { token } = useParams();

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <UserResetPasswordForm token={token || ''} />
    </Container>
  )
}