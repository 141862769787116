import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable } from 'features/ui/data-table';
import { ProcedureError } from './use-procedure-api';

export interface ProcedureErrorsTableProps {
  loading: boolean;
  errors: ProcedureError[];
}

export const ProcedureErrorsTable = ({ loading, errors }: ProcedureErrorsTableProps) => {
  const columnHelper = createColumnHelper<ProcedureError>();

  const columns = [
    columnHelper.accessor("recordNumber", {
      cell: (props) => props.getValue(),
      header: "#"
    }),
    columnHelper.accessor("error", {
      cell: (props) => props.getValue(),
      header: "Error"
    }),
    columnHelper.display({
      id: 'values',
      cell: props => <RowActions row={props.row} />,
      header: "Actions"
    })
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <DataTable title='Errors' data={errors} columns={columns} />
    </Skeleton>
  )
}

interface RowActionsProps {
  row: Row<ProcedureError>
}

export const RowActions = ({ row }: RowActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme='teal' size='sm' variant='outline'>
        Values
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Error</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={5} fontWeight='bold'>
              #{row.original.recordNumber} - {row.original.error}
            </Text>
            <Text>
              {row.original.values.join()}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}