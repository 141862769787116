import { Box, Button, Flex, Spacer } from "@chakra-ui/react";

export interface ProcedureFileFormButtonProps {
  onOpen: () => void;
}

export const ProcedureFileFormButton = ({ onOpen }: ProcedureFileFormButtonProps) => {
  return (
    <Flex>
      <Spacer />
      <Box p='4' >
        <Button onClick={onOpen}>Upload File</Button>
      </Box>
    </Flex>
  )
}