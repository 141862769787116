import { Box, Skeleton } from '@chakra-ui/react';
import { ReportFormButton } from 'features/report/report-form-button';
import { ReportsTable } from 'features/report/reports-table';
import { useGetReports } from 'features/report/use-report-api';
import { useUser } from 'features/user/user-context';

export const ReportsPage = () => {
  const userContext = useUser();
  const { isLoading: isLoadingReports, data: reports } = useGetReports(userContext.facilityId);

  return (
    <Skeleton isLoaded={!isLoadingReports}>
      <Box>
        <ReportsTable reports={reports || []} />
        <ReportFormButton />
      </Box>
    </Skeleton>
  )
}