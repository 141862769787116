import { get, post, put } from "api/api-client";
import { MutationProps, useApiMutation, useApiQuery } from "api/use-api";
import { useApiConfig } from "api/use-api";
import { AxiosRequestConfig } from "axios";

export interface Facility {
  id: string;
  name: string;
  type: string;
}

export interface FacilityRequest {
  id?: string;
  name: string;
  type: string;
}

const getFacilities = async (config: AxiosRequestConfig) => {
  return await get<Facility[]>("/facility", config);
};

const getFacilityTypes = async (config: AxiosRequestConfig) => {
  return await get<string[]>("/facility/type", config);
};

const createFacility = async (request: FacilityRequest, config: AxiosRequestConfig) => {
  return await post<Facility>("/facility", request, config);
};

const updateFacility = async (id: string, request: FacilityRequest, config: AxiosRequestConfig) => {
  return await put<Facility>(`/facility/${id}`, request, config);
};

export const useGetFacilities = () => {
  const config = useApiConfig();
  return useApiQuery('getFacilities', () => getFacilities(config));
};

export const useGetFacilityTypes = () => {
  const config = useApiConfig();
  return useApiQuery('getFacilityTypes', () => getFacilityTypes(config));
};

export const useCreateOrUpdateFacility = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (request: FacilityRequest) => request.id ? updateFacility(request.id, request, config) : createFacility(request, config);
  return useApiMutation(onSubmit, options);
};
