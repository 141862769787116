
const SI_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];

export const formatCurrency = (number: number, index?: number) => {
    if (number === 0) return '' + number;

    // determines SI symbol
    const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

    // get suffix and determine scale
    const suffix = SI_SYMBOLS[tier];
    const scale = 10 ** (tier * 3);

    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return '$' + scaled.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    }) + suffix;
};

export const formatFixed = (number: number) => {
    return number.toFixed();
};

export const getLabelWidth = (data: { name: string }[]) => {
    if (data.length === 0) {
        return 10;
    }

    const clone = [...data];
    return clone.sort(function (a, b) { return b.name.length - a.name.length })[0].name.length * 6;
};

export const getToolTip = (value: any, name: string, props: any) => {
    return ["formatted value", "formatted name"];
};

export const getChartHeight = (data: any[]) => {
    const height = data.length * 55;
    return height < 100 ? 100 : height;
};

