import { Box, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useGetFacilities } from 'features/facility/use-facility-api';
import { useGetUsers } from 'features/user/use-user-api';
import { UserForm } from 'features/user/user-form';
import { UserFormButton } from 'features/user/user-form-button';
import { UsersTable } from 'features/user/users-table';

export const AdminUsersPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isLoadingFacilities, data: facilities } = useGetFacilities();
  const { isLoading: isLoadingUsers, data: users } = useGetUsers();

  return (
    <Box>
      <Skeleton isLoaded={!isLoadingFacilities && !isLoadingUsers}>
        <UsersTable facilities={facilities || []} users={users || []}/>
        <UserForm isOpen={isOpen} onClose={onClose} facilities={facilities || []} />
        <UserFormButton onOpen={onOpen} />
      </Skeleton>
    </Box>
  )
}