import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { GrossRevenueChart } from 'features/report-data/gross-revenue-chart';
import { NetRevenueChart } from 'features/report-data/net-revenue-chart';
import { ProfitabilityChart } from 'features/report-data/profitability-chart';
import { VolumeChart } from 'features/report-data/volume-chart';
import { useGetReportData } from 'features/report/use-report-api';
import { useParams } from 'react-router-dom';

export const ReportDataPage = () => {
  let { id } = useParams();
  const { isLoading, data } = useGetReportData(id || '');

  return (
    <>
      <Tabs isFitted variant='enclosed'>
        <TabList mb='1em'>
          {data?.report.showProfitability && <Tab>Profitability</Tab>}
          {data?.report.showVolume && <Tab>Volume</Tab>}
          {data?.report.showGrossRevenue && <Tab>Gross Revenue</Tab>}
          {data?.report.showNetRevenue && <Tab>Net Revenue</Tab>}
        </TabList>
        <TabPanels>
          {data?.report.showProfitability && <TabPanel>
            <ProfitabilityChart isLoading={isLoading} reportData={data?.profitability} />
          </TabPanel>}
          {data?.report.showVolume && <TabPanel>
            <VolumeChart isLoading={isLoading} reportData={data?.volume} />
          </TabPanel>}
          {data?.report.showGrossRevenue && <TabPanel>
            <GrossRevenueChart isLoading={isLoading} reportData={data?.grossRevenue} />
          </TabPanel>}
          {data?.report.showNetRevenue && <TabPanel>
            <NetRevenueChart isLoading={isLoading} reportData={data?.netRevenue} />
          </TabPanel>}
        </TabPanels>
      </Tabs>
    </>
  )
}