import { Box, Spacer } from '@chakra-ui/react';
import { ProcedureErrorsTable } from 'features/procedure/procedure-errors-table';
import { ProceduresTable } from 'features/procedure/procedures-table';
import { useGetProcedureFile } from 'features/procedure/use-procedure-api';
import { useParams } from 'react-router-dom';

export const ProcedureFilePage = () => {
  const { id } = useParams();
  const { isLoading, data } = useGetProcedureFile(id || '');

  return (
    <Box>
      <ProceduresTable loading={isLoading} procedures={data?.procedures || []} />
      <Spacer m={5} />
      <ProcedureErrorsTable loading={isLoading} errors={data?.errors || []} />
    </Box>
  )
}