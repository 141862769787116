import { createContext, useContext, useState } from "react";
import { User } from "./use-user-api";

/**
 * UserContextType
 * 
 */
export interface UserContextType {
  user: User;
  updateUser: (user: User) => void;
  token: string;
  updateToken: (token: string) => void;
  facilityId: string;
  updateFacilityId: (facilityId: string) => void;
  logout: () => void;
}

const UserContext = createContext<UserContextType>(null!);
export const useUser = () => useContext(UserContext);

const defaultUser: User = {
  id: '',
  email: '',
  authority: '',
  firstName: '',
  lastName: '',
  facilityId: '',
}

/**
 * UserContextProvider
 * 
 * @param children 
 * @returns UserContextProvider
 */
export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>(() => {
    const localUser = localStorage.getItem("user");
    return localUser ? JSON.parse(localUser) : defaultUser;
  });
  const [token, setToken] = useState<string>(localStorage.getItem("token") || '');
  const [facilityId, setFacilityId] = useState<string>(localStorage.getItem("facilityId") || '');

  /**
   * Updates the user.
   * 
   */
  const updateUser = (user: User) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  /**
   * Updates the authentication token.
   * 
   */
  const updateToken = (token: string) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  /**
   * Updates the current facility id.
   * 
   */
  const updateFacilityId = (facilityId: string) => {
    setFacilityId(facilityId);
    localStorage.setItem("facilityId", facilityId);
  };

  /**
   * Logs out the user and removes all authentication details.
   * 
   */
  const logout = () => {

    // remove state variables
    setUser(defaultUser);
    setToken('');
    setFacilityId('');

    // remove local storage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("facilityId");
  };

  let value = {
    user,
    updateUser,
    token,
    updateToken,
    facilityId,
    updateFacilityId,
    logout
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
