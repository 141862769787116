import { Box, Skeleton, useDisclosure } from '@chakra-ui/react';
import { FacilitiesTable } from 'features/facility/facilities-table';
import { FacilityForm } from 'features/facility/facility-form';
import { FacilityFormButton } from 'features/facility/facility-form-button';
import { useGetFacilities, useGetFacilityTypes } from 'features/facility/use-facility-api';

export const AdminFacilitiesPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: isLoadingFacilities, data: facilities } = useGetFacilities();
  const { isLoading: isLoadingFacilityTypes, data: facilityTypes } = useGetFacilityTypes();

  return (
    <Skeleton isLoaded={!isLoadingFacilities && !isLoadingFacilityTypes}>
      <Box>
        <FacilitiesTable facilityTypes={facilityTypes || []} facilities={facilities || []} />
        <FacilityForm facilityTypes={facilityTypes || []} isOpen={isOpen} onClose={onClose} />
        <FacilityFormButton onOpen={onOpen} />
      </Box>
    </Skeleton >
  )
}