import { Button } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { DataTable } from 'features/ui/data-table';
import { useNavigate } from 'react-router-dom';
import { DeleteProcedureButton } from './delete-procedure-file';
import { ProcedureFile } from './use-procedure-api';

export interface ProcedureFilesTableProps {
  procedureFiles: ProcedureFile[];
}

export const ProcedureFilesTable = ({ procedureFiles }: ProcedureFilesTableProps) => {
  const columnHelper = createColumnHelper<ProcedureFile>();

  const columns = [
    columnHelper.accessor("filename", {
      cell: (props) => props.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("timeCreated", {
      cell: (props) => props.getValue(),
      header: "Date"
    }),
    columnHelper.accessor("size", {
      cell: (props) => humanFileSize(props.getValue()),
      header: "File Size"
    }),
    columnHelper.accessor("recordCount", {
      cell: (props) => props.getValue(),
      header: "Record Count"
    }),
    columnHelper.accessor("errorCount", {
      cell: (props) => props.getValue(),
      header: "Error Count"
    }),
    columnHelper.display({
      id: 'actions',
      cell: props => <RowActions row={props.row} />,
      header: "Actions"
    })
  ];

  return (
    <DataTable title='Imported Files' data={procedureFiles} columns={columns} />
  )
}

function humanFileSize(size: number) {
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return Number((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

interface RowActionsProps {
  row: Row<ProcedureFile>
}

export const RowActions = ({ row }: RowActionsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Button mr={2} onClick={() => navigate(`/procedure-file/${row.original.id}`)} colorScheme='teal' size='sm' variant='outline'>
        View
      </Button>
      <DeleteProcedureButton id={row.original.id} />
    </>
  )
}