import { Box, Button, Center, FormControl, FormLabel, HStack, Input, Select, Stack, StackDivider, Switch, Text } from "@chakra-ui/react";
import { ProcedureFile } from "features/procedure/use-procedure-api";
import { ValidationErrors } from "features/ui/ValidationErrors";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../user/user-context";
import { Report, useCreateOrUpdateReport } from "./use-report-api";

export interface ReportFormProps {
  report?: Report;
  procedureFiles: ProcedureFile[];
}

export const ReportForm = ({ procedureFiles, report }: ReportFormProps) => {
  const userContext = useUser();
  const initialRef = useRef(null);
  const navigate = useNavigate();
  const { mutation, errors } = useCreateOrUpdateReport({ onSuccess: () => navigate('/reports') });

  const formHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    // retrieve form data
    const formData = new FormData(event.currentTarget);
    const name = formData.get("name") as string;
    const procedureFileId = formData.get("procedureFileId") as string;
    const showVolume = formData.get("showVolume") as string === 'true';
    const volumeMaxPayors = Number(formData.get("volumeMaxPayors"));
    const volumeMaxProcedures = Number(formData.get("volumeMaxProcedures"));
    const volumeMaxSpecialties = Number(formData.get("volumeMaxSpecialties"));
    const volumeMaxSurgeons = Number(formData.get("volumeMaxSurgeons"));
    const showNetRevenue = formData.get("showNetRevenue") as string === 'true';
    const showGrossRevenue = formData.get("showGrossRevenue") as string === 'true';
    const showProfitability = formData.get("showProfitability") as string === 'true';

    // create the request
    const reportRequest: Report = {
      id: report?.id,
      name,
      procedureFileId,
      facilityId: userContext.facilityId,
      showVolume,
      volumeMaxPayors,
      volumeMaxProcedures,
      volumeMaxSpecialties,
      volumeMaxSurgeons,
      showNetRevenue,
      showGrossRevenue,
      showProfitability
    }

    mutation.mutate(reportRequest);
  }

  return (
    <>
      <form onSubmit={formHandler}>

        <ValidationErrors errors={errors} />

        <Box bg="bg-surface" >

          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Report
            </Text>
            <Text fontSize="sm" color="muted">
              Reports are generated by combining a procedure file and the following chart options.
            </Text>
          </Stack>

          <Stack spacing="7" mt={5} divider={<StackDivider />}>

            <HStack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input name='name' required ref={initialRef} type='text' defaultValue={report?.name} />
              </FormControl>
              <FormControl>
                <FormLabel>Procedure File</FormLabel>
                <Select name="procedureFileId" required placeholder='Select a Procedure File' defaultValue={report?.procedureFileId}>
                  {(procedureFiles).map((f) => <option key={f.id} value={f.id}>{f.filename}</option>)}
                </Select>
              </FormControl>
            </HStack>

            <Stack>
              <Stack justify="space-between" direction="row" spacing="4" mb={2} >
                <Stack spacing="0.5" fontSize="sm">
                  <Text color="emphasized" fontWeight="medium">
                    Volume Chart
                  </Text>
                  <Text color="muted">
                    Procedure volume breakdown.
                  </Text>
                </Stack>
                <Switch name='showVolume' value='true' defaultChecked={report ? report.showVolume : true} />
              </Stack>
              <HStack spacing={2}>
                <FormControl>
                  <FormLabel>Max Payors</FormLabel>
                  <Input name='volumeMaxPayors' type='number' defaultValue={report?.volumeMaxPayors} />
                </FormControl>
                <FormControl>
                  <FormLabel>Max Procedures</FormLabel>
                  <Input name='volumeMaxProcedures' type='number' defaultValue={report?.volumeMaxProcedures} />
                </FormControl>
                <FormControl>
                  <FormLabel>Max Specialties</FormLabel>
                  <Input name='volumeMaxSpecialties' type='number' defaultValue={report?.volumeMaxSpecialties} />
                </FormControl>
                <FormControl>
                  <FormLabel>Max Surgeons</FormLabel>
                  <Input name='volumeMaxSurgeons' type='number' defaultValue={report?.volumeMaxSurgeons} />
                </FormControl>
              </HStack>
            </Stack>

            <Stack justify="space-between" direction="row" spacing="4">
              <Stack spacing="0.5" fontSize="sm">
                <Text color="emphasized" fontWeight="medium">
                  Gross Revenue Chart
                </Text>
                <Text color="muted">
                  Procedure gross revenue breakdown.
                </Text>
              </Stack>
              <Switch name='showGrossRevenue' value='true' defaultChecked={report ? report.showGrossRevenue : true} />
            </Stack>

            <Stack justify="space-between" direction="row" spacing="4">
              <Stack spacing="0.5" fontSize="sm">
                <Text color="emphasized" fontWeight="medium">
                  Net Revenue Chart
                </Text>
                <Text color="muted">
                  Procedure net revenue breakdown.
                </Text>
              </Stack>
              <Switch name='showNetRevenue' value='true' defaultChecked={report ? report.showNetRevenue : true} />
            </Stack>

            <Stack justify="space-between" direction="row" spacing="4">
              <Stack spacing="0.5" fontSize="sm">
                <Text color="emphasized" fontWeight="medium">
                  Profitability Chart
                </Text>
                <Text color="muted">
                  Procedure profitability breakdown.
                </Text>
              </Stack>
              <Switch name='showProfitability' value='true' defaultChecked={report ? report.showProfitability : true} />
            </Stack>

            <Stack justify="space-between" spacing="4">
              <Stack >
                <Center>
                  <Button isLoading={mutation.isLoading} type='submit' colorScheme='blue' mr={3}>
                    Save
                  </Button>
                </Center>
              </Stack>
            </Stack>

          </Stack>
        </Box>

      </form>
    </>
  )
}