import { Box, Container, Skeleton, Stack, StackDivider, useColorModeValue } from '@chakra-ui/react';
import { useGetProcedureFiles } from 'features/procedure/use-procedure-api';
import { ReportForm } from 'features/report/report-form';
import { useUser } from 'features/user/user-context';

export const ReportPage = () => {
  const userContext = useUser();
  const { isLoading, data: procedureFiles } = useGetProcedureFiles(userContext.facilityId);

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box as="section">
        <Container >
          <Box
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            p={{ base: '4', md: '6' }}
          >
            <Stack spacing="5" divider={<StackDivider />}>
              <ReportForm procedureFiles={procedureFiles || []} />
            </Stack>
          </Box>
        </Container>
      </Box>
    </Skeleton>
  )
}