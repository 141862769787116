import { del, get, post, put } from "api/api-client";
import { MutationProps, useApiMutation, useApiQuery } from "api/use-api";
import { useApiConfig } from "api/use-api";
import { AxiosRequestConfig } from "axios";

export interface Report {
  id?: string;
  facilityId: string;
  procedureFileId: string;
  name: string;
  showVolume: boolean;
  volumeMaxPayors?: number;
  volumeMaxProcedures?: number;
  volumeMaxSpecialties?: number;
  volumeMaxSurgeons?: number;
  showNetRevenue: boolean;
  showGrossRevenue: boolean;
  showProfitability: boolean;
}

export interface ReportData {
  report: Report;
  volume: ReportDataVolume;
  netRevenue: ReportDataNetRevenue;
  grossRevenue: ReportDataGrossRevenue;
  profitability: ReportDataProfitability;
}

export interface ReportDataItem {
  name: string;
  value: number;
}

export interface ReportDataVolume {
  byPayor: ReportDataItem[];
  bySurgeon: ReportDataItem[];
  byProcedure: ReportDataItem[];
  bySpecialty: ReportDataItem[];
}

export interface ReportDataNetRevenue {
  byPayor: ReportDataItem[];
  bySurgeon: ReportDataItem[];
  byProcedure: ReportDataItem[];
  bySpecialty: ReportDataItem[];
}

export interface ReportDataGrossRevenue {
  byPayor: ReportDataItem[];
  bySurgeon: ReportDataItem[];
  byProcedure: ReportDataItem[];
  bySpecialty: ReportDataItem[];
}

export interface ReportDataProfitability {
  byPayor: ReportDataProfitabilityItem[];
  bySurgeon: ReportDataProfitabilityItem[];
  byProcedure: ReportDataProfitabilityItem[];
  bySpecialty: ReportDataProfitabilityItem[];
}

export interface ReportDataProfitabilityItem {
  name: string;
  value: number;
  payors: ReportDataItem[];
  surgeons: ReportDataItem[];
  procedures: ReportDataItem[];
  specialties: ReportDataItem[];
}

const getReports = async (facilityId: string, config: AxiosRequestConfig) => {
  return await get<Report[]>(`/report/facility/${facilityId}`, config);
};

const getReport = async (id: string, config: AxiosRequestConfig) => {
  return await get<Report>(`/report/${id}`, config);
};

const getReportData = async (id: string, config: AxiosRequestConfig) => {
  return await get<ReportData>(`/report/report-data/${id}`, config);
};

const createReport = async (request: Report, config: AxiosRequestConfig) => {
  return await post<Report>('/report', request, config);
};

const updateReport = async (id: string, request: Report, config: AxiosRequestConfig) => {
  return await put<Report>(`/report/${id}`, request, config);
};

const deleteReport = async (id: string, config: AxiosRequestConfig) => {
  return await del(`/report/${id}`, config);
};

export const useGetReports = (facilityId: string) => {
  const config = useApiConfig();
  return useApiQuery('getReports', () => getReports(facilityId, config));
};

export const useGetReport = (id: string) => {
  const config = useApiConfig();
  return useApiQuery(`getReport-${id}`, () => getReport(id, config));
};

export const useGetReportData = (id: string) => {
  const config = useApiConfig();
  return useApiQuery(`getReportData-${id}`, () => getReportData(id, config));
};

export const useCreateOrUpdateReport = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (request: Report) => request.id ? updateReport(request.id, request, config) : createReport(request, config);
  return useApiMutation(onSubmit, options);
};

export const useDeleteReport = (options: MutationProps) => {
  const config = useApiConfig();
  const onSubmit = (id: string) => deleteReport(id, config);
  return useApiMutation(onSubmit, options);
};
