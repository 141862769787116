import { Button, ButtonGroup, Card, CardBody, IconButton, Skeleton, Stack, StackDivider, Text } from '@chakra-ui/react';
import { ReportDataItem, ReportDataProfitability, ReportDataProfitabilityItem } from 'features/report/use-report-api';
import { useEffect, useState } from 'react';
import { BiArrowFromBottom } from 'react-icons/bi';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatFixed, getChartHeight, getLabelWidth } from './chart-util';

const colors = [
  "#ff8686",
  "#f9868a",
  "#f2868e",
  "#ec8793",
  "#e58797",
  "#df879b",
  "#d9879f",
  "#d287a3",
  "#cc88a7",
  "#c588ac",
  "#bf88b0",
  "#b888b4",
  "#b288b8",
  "#ac89bc",
  "#a589c0",
  "#9f89c5",
  "#9889c9",
  "#928acd",
  "#8c8ad1",
  "#858ad5",
  "#7f8ad9",
  "#788ade",
  "#728be2",
  "#6b8be6",
  "#658bea",
  "#5f8bee",
  "#588bf2",
  "#528cf7",
  "#4b8cfb",
  "#458cff"];

export interface ProfitabilityChartProps {
  isLoading: boolean;
  reportData?: ReportDataProfitability
}

export const ProfitabilityChart = ({ isLoading, reportData }: ProfitabilityChartProps) => {
  const [chartData, setChartData] = useState<ReportDataItem[]>([]);
  const [chartActive, setChartActive] = useState('byPayor');
  const [chartItem, setChartItem] = useState<ReportDataProfitabilityItem>();
  const [chartItemData, setChartItemData] = useState<ReportDataItem[]>([]);
  const [chartItemName, setChartItemName] = useState<string>();
  const [chartItemActive, setChartItemActive] = useState<string>();

  useEffect(() => {
    setChartData(reportData?.byPayor || []);
  }, [reportData]);

  const onChartClick = (id: string) => {

    if (id === 'byPayor') {

      if (chartItemActive) {
        setChartItemData(chartItem?.payors || []);
        setChartItemActive('byPayor');
      } else {
        setChartActive('byPayor');
        setChartData(reportData?.byPayor || []);
      }

    }

    if (id === 'byProcedure') {

      if (chartItemActive) {
        setChartItemData(chartItem?.procedures || []);
        setChartItemActive('byProcedure');
      } else {
        setChartActive('byProcedure');
        setChartData(reportData?.byProcedure || []);
      }

    }

    if (id === 'bySpecialty') {

      if (chartItemActive) {
        setChartItemData(chartItem?.specialties || []);
        setChartItemActive('bySpecialty');
      } else {
        setChartActive('bySpecialty');
        setChartData(reportData?.bySpecialty || []);
      }

    }

    if (id === 'bySurgeon') {

      if (chartItemActive) {
        setChartItemData(chartItem?.surgeons || []);
        setChartItemActive('bySurgeon');
      } else {
        setChartActive('bySurgeon');
        setChartData(reportData?.bySurgeon || []);
      }

    }

  }

  const onChartItem = (item: ReportDataProfitabilityItem) => {
    setChartItem(item);
    setChartItemName(item.name);

    if (chartActive === 'byPayor') {
      setChartItemData(item.procedures);
      setChartItemActive('byProcedure');
    }

    if (chartActive === 'byProcedure') {
      setChartItemData(item.payors);
      setChartItemActive('byPayor');
    }

    if (chartActive === 'bySpecialty') {
      setChartItemData(item.payors);
      setChartItemActive('byPayor');
    }

    if (chartActive === 'bySurgeon') {
      setChartItemData(item.payors);
      setChartItemActive('byPayor');
    }

  }

  const onReset = () => {
    setChartItem(undefined);
    setChartItemData([]);
    setChartItemName(undefined);
    setChartItemActive(undefined);
  }

  return (
    <>
      {reportData &&
        <Skeleton isLoaded={!isLoading} mb={10}>
          <Card bg='white'>
            <CardBody>

              <Stack spacing="5" divider={<StackDivider />}>
                <Stack justify="space-between" direction={{ base: 'column', sm: 'row' }} spacing="5" mb={5}>
                  <Stack spacing="1">
                    <Text fontSize="lg" fontWeight="medium">
                      {chartItemName ? `Profitability of ${chartItemName} by` : 'Profitability by'}
                      {!chartItemActive && chartActive === 'byPayor' && ' Payor'}
                      {!chartItemActive && chartActive === 'byProcedure' && ' Procedure'}
                      {!chartItemActive && chartActive === 'bySpecialty' && ' Specialty'}
                      {!chartItemActive && chartActive === 'bySurgeon' && ' Surgeon'}
                      {chartItemActive && chartItemActive === 'byPayor' && ' Payor'}
                      {chartItemActive && chartItemActive === 'byProcedure' && ' Procedure'}
                      {chartItemActive && chartItemActive === 'bySpecialty' && ' Specialty'}
                      {chartItemActive && chartItemActive === 'bySurgeon' && ' Surgeon'}
                    </Text>
                    <Text fontSize="sm" color="muted">
                      Overall profitability by category. Click on a bar to drill into the chart.
                    </Text>
                  </Stack>
                  <Stack spacing="1">
                    <ButtonGroup display={'flex'} flexWrap={'wrap'}>
                      <IconButton ml={2} my={1} variant="outline" isActive={!!chartItemActive} disabled={!chartItemActive} onClick={() => onReset()} aria-label='Up' icon={<BiArrowFromBottom />} />
                      <Button my={1} variant="outline" isActive={chartItemActive ? chartItemActive === 'byPayor' : chartActive === 'byPayor'} onClick={() => onChartClick('byPayor')} >By Payor</Button>
                      <Button my={1} variant="outline" isActive={chartItemActive ? chartItemActive === 'byProcedure' : chartActive === 'byProcedure'} onClick={() => onChartClick('byProcedure')} >By Procedure</Button>
                      <Button my={1} variant="outline" isActive={chartItemActive ? chartItemActive === 'bySpecialty' : chartActive === 'bySpecialty'} onClick={() => onChartClick('bySpecialty')} >By Specialty</Button>
                      <Button my={1} variant="outline" isActive={chartItemActive ? chartItemActive === 'bySurgeon' : chartActive === 'bySurgeon'} onClick={() => onChartClick('bySurgeon')} >By Surgeon</Button>
                    </ButtonGroup>
                  </Stack>
                </Stack>
              </Stack>

              <ResponsiveContainer height={getChartHeight(chartItemData?.length > 0 ? chartItemData : chartData)} >
                <BarChart layout="vertical" data={chartItemData?.length > 0 ? chartItemData : chartData} margin={{ top: 5, right: 35, left: 35, bottom: 5 }}>
                  <YAxis tick={{ fontSize: 14, color: 'black' }}
                    tickLine={false}
                    width={getLabelWidth(chartItemData?.length > 0 ? chartItemData : chartData)}
                    type="category" dataKey="name" />
                  <XAxis type="number" />
                  <Tooltip formatter={(value) => [value, "Profitability"]} />
                  <Bar dataKey="value" fill="#8884d8" onClick={chartItemActive ? () => { } : onChartItem} >
                    {colors.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))
                    }
                    <LabelList formatter={formatFixed} dataKey="value" position="right" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>

            </CardBody>
          </Card>
        </Skeleton>
      }
    </>
  )
}