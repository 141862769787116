import { theme } from '@chakra-ui/pro-theme';
import { ChakraProvider } from '@chakra-ui/react';
import { UserContextProvider } from 'features/user/user-context';
import { IndexPage } from 'pages';
import { AdminFacilitiesPage } from 'pages/admin-facilities';
import { AdminUsersPage } from 'pages/admin-users';
import { AuthorizedPage } from 'pages/authorized-page';
import { ForgotPasswordPage } from 'pages/forgot-password';
import { ImportDataPage } from 'pages/import-data';
import { LoginPage } from 'pages/login';
import { ProcedureFilePage } from 'pages/procedure-file';
import { ReportPage } from 'pages/report';
import { ReportDataPage } from 'pages/report-data';
import { ReportEditPage } from 'pages/report-edit';
import { ReportsPage } from 'pages/reports';
import { ResetPasswordPage } from 'pages/reset-password';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter, Route, Routes } from "react-router-dom";
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={new QueryClient()}>
        <UserContextProvider>
          <HashRouter>
            <Routes>
              <Route>
                <Route path="/login" element={
                  <LoginPage />
                } />
                <Route path="/forgot-password" element={
                  <ForgotPasswordPage />
                } />
                <Route path="/reset-password/:token" element={
                  <ResetPasswordPage />
                } />
                <Route path="/" element={
                  <AuthorizedPage>
                    <IndexPage />
                  </AuthorizedPage>
                } />
                <Route path="/admin/users" element={
                  <AuthorizedPage>
                    <AdminUsersPage />
                  </AuthorizedPage>
                } />
                <Route path="/admin/facilities" element={
                  <AuthorizedPage>
                    <AdminFacilitiesPage />
                  </AuthorizedPage>
                } />
                <Route path="/import-data" element={
                  <AuthorizedPage>
                    <ImportDataPage />
                  </AuthorizedPage>
                } />
                <Route path="/procedure-file/:id" element={
                  <AuthorizedPage>
                    <ProcedureFilePage />
                  </AuthorizedPage>
                } />
                <Route path="/reports" element={
                  <AuthorizedPage>
                    <ReportsPage />
                  </AuthorizedPage>
                } />
                <Route path="/report" element={
                  <AuthorizedPage>
                    <ReportPage />
                  </AuthorizedPage>
                } />
                <Route path="/report/:id" element={
                  <AuthorizedPage>
                    <ReportEditPage />
                  </AuthorizedPage>
                } />
                <Route path="/report-data/:id" element={
                  <AuthorizedPage>
                    <ReportDataPage />
                  </AuthorizedPage>
                } />
              </Route>
            </Routes>
          </HashRouter>
        </UserContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);