import { Box, ButtonGroup, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { ColumnDef, flexRender, getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";
import { BiCaretDown, BiCaretUp, BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage, BiSearch } from "react-icons/bi";

export type DataTableProps<Data extends object> = {
  title: string;
  data: Data[];
  columns: ColumnDef<Data, any>[];
};

export function DataTable<Data extends object>({ title, data, columns }: DataTableProps<Data>) {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [globalFilter, setGlobalFilter] = useState('')

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    globalFilterFn: (row, columnId, filterValue) => {
      const safeValue = ((): string => {
        const value = row.getValue(columnId);
        return value === null ? '' : String(value);
      })();
      return safeValue.toLowerCase().includes(filterValue.toLowerCase());
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues()
  })

  return (
    <Box
      bg="bg-surface"
      boxShadow={{ base: 'none', md: useColorModeValue('sm', 'sm-dark') }}
      borderRadius={useBreakpointValue({ base: 'none', md: 'lg' })}
    >
      <Stack spacing="5">
        <Box px={{ base: '4', md: '6' }} pt="5">
          <Stack direction={{ base: 'column', md: 'row' }} justify="space-between">
            <Text fontSize="lg" fontWeight="medium">
              {title}
            </Text>
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={BiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" onChange={e => setGlobalFilter(String(e.target.value))}
              />
            </InputGroup>
          </Stack>
        </Box>
        <Box overflowX="auto">
          <Table>
            <Thead>
              {table.getHeaderGroups().map(headerGroup => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <Th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <Box
                            style={{ cursor: header.column.getCanSort() ? 'pointer' : '' }}
                            onClick={header.column.getToggleSortingHandler()}>

                            {flexRender(header.column.columnDef.header, header.getContext())}

                            {header.column.getIsSorted() === 'asc' &&
                              <Icon as={BiCaretDown} ml='1' color="muted" />}

                            {header.column.getIsSorted() === 'desc' &&
                              <Icon as={BiCaretUp} ml='1' color="muted" />}

                          </Box>
                        )}
                      </Th>
                    )
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Box>
        <Box px={{ base: '4', md: '6' }} pb="5">
          <HStack spacing="3" justify="space-between">

            {!isMobile && (
              <Text color="muted" fontSize="sm">
                Page {table.getState().pagination.pageIndex + 1} of {' '}
                {table.getPageCount()}
                {' '}
                ({table.getPrePaginationRowModel().rows.length}
                {' '}
                {table.getPrePaginationRowModel().rows.length === 1 ? 'Result' : 'Results'})
              </Text>
            )}

            <ButtonGroup
              spacing="3"
              justifyContent="space-between"
              width={{ base: 'full', md: 'auto' }}
              variant="secondary"
            >
              <IconButton
                className="border rounded p-1"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                aria-label='First Page'
                icon={<BiFirstPage />}
              />

              <IconButton
                className="border rounded p-1"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                aria-label='Previous Page'
                icon={<BiChevronLeft />}
              />

              <IconButton
                className="border rounded p-1"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                aria-label='Next Page'
                icon={<BiChevronRight />}
              />

              <IconButton
                className="border rounded p-1"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
                aria-label='Last Page'
                icon={<BiLastPage />}
              />

            </ButtonGroup>
          </HStack>
        </Box>
      </Stack>
    </Box>
  );
}